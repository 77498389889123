import * as React from 'react';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Person from '@mui/icons-material/Person';
import Dns from '@mui/icons-material/Dns';

import urlAdemi from './assets/ademi.png';
import urlAfi from './assets/afi.png';
import urlAgora from './assets/agora.png';
import urlAstech from './assets/astech.png';
import urlAtal from './assets/atal.png';
import urlAxel from './assets/teamnet.png';
import urlBergerLevraultFinances from './assets/berger_levrault.png';
import urlCegid from './assets/cegid.png';
import urlCGI from './assets/cgi.png';
import urlChronos from './assets/chronos.png';
import urlCirilEnfance from './assets/ciril_enfance.png';
import urlCirilFinances from './assets/ciril_finances.png';
import urlCirilRH from './assets/ciril_rh.png';
import urlCityweb from './assets/cityweb.png';
import urlConcerto from './assets/concerto.png';
import urlCoriolis from './assets/coriolis.png';
import urlCosoluce from './assets/cosoluce.png';
import urlEksae from './assets/eksae.png';
import urlElap from './assets/elap.png';
import urlElisath from './assets/elisath.png';
import urlGFI from './assets/gfi.png';
import urlHoranet from './assets/horanet.png';
import urlImplicit from './assets/implicit.png';
import urlIncovar from './assets/incovar.png';
import urlJVS from './assets/jvs.png';
import urlMaelis from './assets/maelis.png';
import urlMelodie from './assets/melodie.png';
import urlMillesimeMad from './assets/millesime_mad.png';
import urlNextads from './assets/nextads.png';
import urlOxalis from './assets/oxalis.png';
import urlPhaseWeb from './assets/phase_web.png';
import urlSegilog from './assets/segilog.png';
import urlSifac from './assets/sifac.png';
import urlSolis from './assets/solis.png';
import urlSymetri from './assets/symetri.png';
import urlTechnocarte from './assets/technocarte.png';
// import urlDefault from './assets/default.png';

// NOTA BENE: the fields here must respect the auth_data schema defined in the backend:
// vision-back/vision/explore/schemas.py

const oracleFields = [
    {
        key: 'username',
        name: 'username',
        type: 'text',
        adornment: <Person />,
        validators: ['required'],
    },
    {
        key: 'password',
        name: 'password',
        type: 'password',
        adornment: <VpnKeyIcon />,
        validators: ['required'],
    },
    {
        key: 'dsnString',
        name: 'dsnString',
        type: 'text',
        adornment: <Dns />,
        validators: ['required'],
    },
];

export const mantyIntegrations = [
    {
        software: 'afi_kaila',
        title: 'AFI Kaïla',
        image: urlAfi,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'afi_sedna',
        title: 'AFI Sedna',
        image: urlAfi,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'ciril_rh',
        title: 'Ciril RH',
        image: urlCirilRH,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'cegid_rh',
        title: 'Eksae RH',
        image: urlEksae,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'jvs_rh',
        title: 'JVS RH',
        image: urlJVS,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'jvs_finances',
        title: 'JVS Finances',
        image: urlJVS,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'astre_rh',
        title: 'Astre RH',
        image: urlGFI,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'incovar',
        title: 'Incovar',
        image: urlIncovar,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'ciril_finances',
        title: 'Ciril Finances',
        image: urlCirilFinances,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'cegid_finances',
        title: 'Eksae Finances',
        image: urlEksae,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'cegid_finances_y2',
        title: 'Eksae Finances Y2',
        image: urlEksae,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'eksae_visagf',
        title: 'Eksae Finances VISAGF',
        image: urlEksae,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'sedit_finances',
        title: 'Sedit Finances',
        image: urlBergerLevraultFinances,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'sedit_rh',
        title: 'Sedit Ressources Humaines',
        image: urlBergerLevraultFinances,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'astre_gf',
        title: 'Astre GF',
        image: urlGFI,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'segilog',
        title: 'Segilog',
        image: urlSegilog,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'phase_web',
        title: 'Phase Web',
        image: urlPhaseWeb,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'ciril_enfance',
        title: 'Ciril Services à la population',
        image: urlCirilEnfance,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'concerto',
        title: 'Concerto',
        image: urlConcerto,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'maelis',
        title: 'Maelis',
        image: urlMaelis,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'axel',
        title: 'Axel',
        image: urlAxel,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'technocarte',
        title: 'Technocarte',
        image: urlTechnocarte,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'agora',
        title: 'Agora',
        image: urlAgora,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'horanet',
        title: 'Horanet',
        image: urlHoranet,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'elisath',
        title: 'Elisath',
        image: urlElisath,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'atal',
        title: 'Atal',
        image: urlAtal,
        type: 'technical_services',
        authFields: oracleFields,
    },
    {
        software: 'astech',
        title: 'Astech',
        image: urlAstech,
        type: 'technical_services',
        authFields: oracleFields,
    },
    {
        software: 'implicit',
        title: 'Implicit',
        image: urlImplicit,
        type: 'social',
        authFields: oracleFields,
    },
    {
        software: 'oxalis',
        title: 'Oxalis',
        image: urlOxalis,
        type: 'urbanism',
        authFields: oracleFields,
    },
    {
        software: 'cityweb',
        title: 'Cityweb',
        image: urlCityweb,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'melodie',
        title: 'Mélodie',
        image: urlMelodie,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'symetri',
        title: 'Symetri',
        image: urlSymetri,
        type: 'technical_services',
        authFields: oracleFields,
    },
    {
        software: 'ademi',
        title: 'Adémi - Pesage',
        image: urlAdemi,
        type: 'technical_services',
        authFields: oracleFields,
    },
    {
        software: 'sifac',
        title: 'SIFAC',
        image: urlSifac,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'grand_angle',
        title: 'Grand Angle',
        image: urlCGI,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'coriolis',
        title: 'Coriolis',
        image: urlCoriolis,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'cosoluce_finances',
        title: 'Cosoluce Finances',
        image: urlCosoluce,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'elap',
        title: 'Elap',
        image: urlElap,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'sedit_finances_gfde',
        title: 'Sedit Finance GFDE',
        image: urlBergerLevraultFinances,
        type: 'finances',
        authFields: oracleFields,
    },
    {
        software: 'sedit_rhde',
        title: 'Sedit RHDE',
        image: urlBergerLevraultFinances,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'cegid_rh_carrus',
        title: 'Cegid RH Carrus',
        image: urlCegid,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'cegid_rh_relyens',
        title: 'Cegid RH Relyens',
        image: urlCegid,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'chronos',
        title: 'Chronos',
        image: urlChronos,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'cosoluce_rh',
        title: 'Cosoluce RH',
        image: urlCosoluce,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'phase_web_rh',
        title: 'Phase Web RH',
        image: urlPhaseWeb,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'sedit_rh_relyens',
        title: 'Sedit RH Relyens',
        image: urlBergerLevraultFinances,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'sedit_rhde_relyens',
        title: 'Sedit RHDE Relyens',
        image: urlBergerLevraultFinances,
        type: 'hr',
        authFields: oracleFields,
    },
    {
        software: 'solis_ase',
        title: 'Solis ASE',
        image: urlSolis,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'solis_rsa',
        title: 'Solis RSA',
        image: urlSolis,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'solis_fsl',
        title: 'Solis FSL',
        image: urlSolis,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'technocarte_pg',
        title: 'Technocarte PostgreSQL',
        image: urlTechnocarte,
        type: 'population',
        authFields: oracleFields,
    },
    {
        software: 'millesime_mad',
        title: 'Millesime MAD',
        image: urlMillesimeMad,
        type: 'mad',
        authFields: oracleFields,
    },
    {
        software: 'nextads',
        title: 'Nextads',
        image: urlNextads,
        type: 'urbanism',
        authFields: oracleFields,
    },
    {
        software: 'e_magnus_rh',
        title: 'E-Magnus RH',
        image: urlBergerLevraultFinances,
        type: 'hr',
        authFields: oracleFields,
    },
];
